export const AboutMe = () => (
    <svg
      aria-label="Project icon"
      role="img"
      viewBox="-5.0 -10.0 110.0 135.0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M90.625 12.766h-81.25c-2.1562 0-3.9062 1.75-3.9062 3.9062v50c0 2.1562 1.75 3.9062 3.9062 3.9062h35.844c1 3.6406-0.28125 7.2344-3.9062 10.938h-2.7812c-2.7344 0-4.9531 2.2188-4.9531 4.9531 0 0.4375 0.34375 0.78125 0.78125 0.78125h31.25c0.4375 0 0.78125-0.34375 0.78125-0.78125 0-2.7344-2.2188-4.9531-4.9531-4.9531h-2.7812c-3.625-3.7188-4.8906-7.2969-3.9062-10.938h35.875c2.1562 0 3.9062-1.75 3.9062-3.9062v-50c0-2.1562-1.75-3.9062-3.9062-3.9062zm-29.172 70.312c1.5938 0 2.9375 1.1094 3.2969 2.6094h-29.5c0.35938-1.4844 1.7031-2.6094 3.2969-2.6094zm-6.7969-4.1562c0.51562 0.875 1.1406 1.7344 1.8906 2.5938h-13.078c0.75-0.85938 1.375-1.7344 1.8906-2.5938zm-7.6875-4.1875h6.0625c0.15625 0.875 0.42188 1.7344 0.8125 2.6094h-7.6875c0.375-0.85938 0.65625-1.7344 0.8125-2.6094zm-0.10938-4.1562h6.2656c-0.20312 0.875-0.28125 1.7344-0.26562 2.6094h-5.75c0.03125-0.875-0.046875-1.75-0.25-2.6094zm46.109-3.9062c0 1.2969-1.0469 2.3438-2.3438 2.3438h-81.25c-1.2969 0-2.3438-1.0469-2.3438-2.3438v-4.4219h85.938zm0-6h-85.938v-44c0-1.2969 1.0469-2.3438 2.3438-2.3438h81.25c1.2969 0 2.3438 1.0469 2.3438 2.3438z"
      />
      <path
        fill="currentColor"
        d="M16.656 63.469c-1.1875 0-2.1562 0.96875-2.1562 2.1719 0 1.1875 0.96875 2.1719 2.1562 2.1719s2.1719-0.96875 2.1719-2.1719-0.96875-2.1719-2.1719-2.1719zm0 2.7656c-0.32812 0-0.59375-0.26562-0.59375-0.60938 0-0.32812 0.26562-0.60938 0.59375-0.60938s0.60938 0.26562 0.60938 0.60938c0 0.32812-0.26562 0.60938-0.60938 0.60938z"
      />
      <path
        fill="currentColor"
        d="M22.188 63.469c-1.1875 0-2.1719 0.96875-2.1719 2.1719 0 1.1875 0.96875 2.1719 2.1719 2.1719s2.1719-0.96875 2.1719-2.1719-0.98438-2.1719-2.1719-2.1719zm0 2.7656c-0.32812 0-0.60938-0.26562-0.60938-0.60938 0-0.32812 0.26562-0.60938 0.60938-0.60938s0.60938 0.26562 0.60938 0.60938c0 0.32812-0.28125 0.60938-0.60938 0.60938z"
      />
      <path
        fill="currentColor"
        d="M27.719 63.469c-1.1875 0-2.1719 0.96875-2.1719 2.1719 0 1.1875 0.96875 2.1719 2.1719 2.1719s2.1562-0.96875 2.1562-2.1719-0.96875-2.1719-2.1562-2.1719zm0 2.7656c-0.32812 0-0.60938-0.26562-0.60938-0.60938 0-0.32812 0.26562-0.60938 0.60938-0.60938s0.60938 0.26562 0.60938 0.60938c0 0.32812-0.28125 0.60938-0.60938 0.60938z"
      />
      <path
        fill="currentColor"
        d="M12.5 70.234h-12.5c-0.4375 0-0.78125 0.34375-0.78125 0.78125s0.34375 0.78125 0.78125 0.78125h12.5c0.4375 0 0.78125-0.34375 0.78125-0.78125s-0.34375-0.78125-0.78125-0.78125z"
      />
      <path
        fill="currentColor"
        d="M18.75 56.516h-7.5469v-26.312c0-0.4375-0.34375-0.78125-0.78125-0.78125s-0.78125 0.34375-0.78125 0.78125v27.078c0 0.4375 0.34375 0.78125 0.78125 0.78125h8.3281c0.4375 0 0.78125-0.34375 0.78125-0.78125s-0.34375-0.76562-0.78125-0.76562z"
      />
      <path
        fill="currentColor"
        d="M10.422 26.828c0.4375 0 0.78125-0.34375 0.78125-0.78125v-2.0781c0-0.4375-0.34375-0.78125-0.78125-0.78125s-0.78125 0.34375-0.78125 0.78125v2.0781c0 0.42188 0.34375 0.78125 0.78125 0.78125z"
      />
      <path
        fill="currentColor"
        d="M50 16.078c-11.922 0-21.609 9.7031-21.609 21.609 0 6.6875 3.0469 12.672 7.8281 16.641 0.078125 0.09375 0.17188 0.17188 0.28125 0.21875 3.7031 2.9688 8.3906 4.7656 13.5 4.7656s9.7969-1.7969 13.5-4.7656c0.10938-0.046875 0.20312-0.125 0.28125-0.21875 4.7812-3.9688 7.8281-9.9531 7.8281-16.641 0-11.906-9.6875-21.609-21.609-21.609zm0 41.672c-4.6406 0-8.9062-1.6094-12.312-4.2656 0.92188-6.0469 6.0938-10.562 12.25-10.594h0.0625 0.0625c6.1562 0.03125 11.328 4.5469 12.25 10.594-3.3906 2.6719-7.6719 4.2656-12.312 4.2656zm-8.0469-24.469c0-4.4375 3.6094-8.0469 8.0625-8.0469s8.0625 3.6094 8.0625 8.0469c0 4.4219-3.5938 8.0312-8.0156 8.0469h-0.046875-0.03125c-4.4375-0.03125-8.0312-3.625-8.0312-8.0469zm21.734 19.031c-1.1094-4.9844-4.8125-8.8906-9.5625-10.359 3.25-1.5469 5.5-4.8438 5.5-8.6719 0-5.2969-4.3125-9.6094-9.625-9.6094s-9.625 4.3125-9.625 9.6094c0 3.8281 2.25 7.125 5.4844 8.6719-4.75 1.4531-8.4688 5.375-9.5781 10.359-3.9062-3.6562-6.3594-8.8438-6.3594-14.609 0-11.062 9-20.047 20.047-20.047 11.062 0 20.047 9 20.047 20.047 0.03125 5.75-2.4219 10.938-6.3281 14.609z"
      />
    </svg>
  );